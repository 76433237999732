<template>
  <div>
    <el-table
      v-if="combos.length"
      :data="combos"
      @select-all="setSelectedCombos"
      @selection-change="setSelectedCombos"
    >
      <el-table-column
        v-if="allowMarking"
        type="selection"
        width="45"
      ></el-table-column>
      <el-table-column width="200">
        <template slot="header">
          <div class="table--header">
            <span>Combination</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatMealName(formatMealNameWithType(scope.row.name)) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Menu Type</span>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            v-if="scope.row.name.toLowerCase().includes('corporate standard')"
          >
            Corporate Standard
          </div>
          <div
            v-else-if="
              scope.row.name.toLowerCase().includes('individual standard')
            "
          >
            Individual Standard
          </div>
          <div v-else-if="scope.row.name.toLowerCase().includes('lite')">
            Lite
          </div>
          <div v-else-if="scope.row.name.toLowerCase().includes('basic')">
            Basic
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <span> Order Type </span>
        </template>
        <template slot-scope="scope">
          <p class="text-grey-primary">
            {{ formatName(scope.row.order_type) }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot="header">
          <div class="table--header">
            <span>Count</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.count }}
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Plating Status</span>
          </div>
        </template>
        <template slot-scope="scope">
          <order-function-status
            :period="period"
            :allow-status-show="allowStatusShow"
            :allow-marking="allowMarking"
            :mark-function="'plating'"
            :function-status="scope.row.plated"
            :function-time="scope.row.plated_at"
            :function-actor="scope.row.plated_by"
            @action="setSelectedCombo(scope.row, 'plated')"
          />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Sealing Status</span>
          </div>
        </template>
        <template slot-scope="scope">
          <order-function-status
            :period="period"
            :allow-status-show="allowStatusShow"
            :allow-marking="allowMarking"
            :mark-function="'sealing'"
            :function-status="scope.row.sealed"
            :function-time="scope.row.sealed_at"
            :function-actor="scope.row.sealed_by"
            @action="setSelectedCombo(scope.row, 'sealed')"
          />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Sleeving Status</span>
          </div>
        </template>
        <template slot-scope="scope">
          <order-function-status
            :period="period"
            :allow-status-show="allowStatusShow"
            :allow-marking="allowMarking"
            :mark-function="'sleeving'"
            :function-status="scope.row.sleeved"
            :function-time="scope.row.sleeved_at"
            :function-actor="scope.row.sleeved_by"
            @action="setSelectedCombo(scope.row, 'sleeved')"
          />
        </template>
      </el-table-column>
    </el-table>
    <eden-content-empty v-else :text="'No combos'" />
  </div>
</template>

<script>
import OrderFunctionStatus from "@/components/Stewards/OrderFunctionStatus";

export default {
  name: "ComboCheckItemsTable",
  props: {
    period: {
      type: String,
      default: "",
    },
    combos: {
      type: Array,
      default() {
        return [];
      },
    },
    allowMarking: {
      type: Boolean,
      default: false,
    },
    allowStatusShow: {
      type: Boolean,
      default: false,
    },
  },
  components: { OrderFunctionStatus },
  data() {
    return {};
  },
  methods: {
    setSelectedCombos(combos) {
      this.$emit("set-selected-combos", combos);
    },

    setSelectedCombo(combo, action) {
      this.$emit("set-selected-combo", { combo, action });
    },
  },
};
</script>
