<template>
  <div>
    <el-dialog
      title="Export"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="key === 'combo' || key === 'count'"
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="exporting"
            @click="getExportData"
            :disabled="!form.format || !form.fields.length"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ComboCheckExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    period: {
      type: String,
      default: "",
    },
    custom: {
      type: Object,
      default() {
        return {};
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        combo: "Combination",
        count: "Count",
        order_type: "Order Type",
        plated: "Plating Status",
        sealed: "Sealing Status",
        sleeved: "Sleeving Status",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["combo", "count"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["combo", "count"];
      this.form.format = "";
      this.exporting = false;
      this.shouldShow = false;
    },

    getExportData() {
      this.loading = true;
      this.formatExportData(this.data);
      this.mixpanelTrack({
        event: "combo_check_export",
      });
    },

    formatExportData(data) {
      let title;

      if (this.period) {
        let from = this.custom.from
          ? this.formatDate(this.custom.from, "do m, y")
          : null;
        let to = this.custom.to
          ? this.formatDate(this.custom.to, "do m, y")
          : null;
        let period;

        if (this.custom) {
          period = `${from} - ${to}`;
        } else {
          const fPeriod = this.formatPeriodDate(this.period, "do m, y");
          period = fPeriod.start
            ? `${fPeriod.start} - ${fPeriod.end}`
            : fPeriod;
        }
        let updated = this.formatDateAndTime(this.dataTime, "do m, y");
        let date = this.formatDateAndTime(new Date(), "do m, y");
        title = `Combo Check - ${period}\nDownloaded at - ${updated}\nExported at - ${date}`;
      } else {
        const date = this.formatDate(this.$route.params.date, "ddd do, m y");
        title = `Combo Check - ${date}`;
      }

      let headers = {
        sn: "S/N",
      };
      let extra_headers = {};
      let combocheckData = [];
      this.form.fields.forEach(
        (key) => (extra_headers[key] = this.fields[key])
      );
      headers = { ...headers, ...extra_headers };
      combocheckData = data.map((item, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "combo":
              export_object[key] = this.formatName(item.name);
              break;
            case "count":
              export_object[key] = item.count;
              break;
              case "order_type":
              export_object[key] = item.order_type;
              break;
            case "plated":
              export_object[key] = item.plated ? "Plated" : "Not plated";
              break;
            case "sealed":
              export_object[key] = item.sealed ? "Sealed" : "Not sealed";
              break;
            case "sleeved":
              export_object[key] = item.sleeved ? "Sleeved" : "Not sleeved";
              break;
            default:
              break;
          }
        });
        return export_object;
      });
      this.exportData = {
        title,
        headers,
        data: combocheckData,
        fileName: "Combo Items",
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.downloadComboCsv,
.downloadComboXls {
  position: absolute;
  opacity: 0;
}
</style>
