export const formatItems = (mealItems, type) => {
  let items = [];

  Object.keys(mealItems).forEach((item) => {
    let data = {
      name: item,
      count: mealItems[item].length,
      order_type: mealItems[item][0].order_type
    };

    if (type === "combo") {
      const status = mealItems[item][0];
      data = {
        ...data,
        csp_ids: mealItems[item].reduce(
          (ids, item) => [...ids, ...item.csp_id.map((id) => id)],
          []
        ),
        plated: status.is_plated[0],
        plated_at: status.is_plated_time[0],
        plated_by: status.plated_by.name ?? "-",
        sealed: status.is_sealed[0],
        sealed_at: status.is_sealed_time[0],
        sealed_by: status.sealed_by.name ?? "-",
        sleeved: status.is_sleeved[0],
        sleeved_at: status.is_sleeved_time[0],
        sleeved_by: status.sleeved_by.name ?? "-",
        order_type: status.order_type
      };
      data.csp_ids = [...new Set(data.csp_ids)];
    }
    items.push(data);
  });
  return items;
};

export const formatAllergies = (item) => {
  const allergies = {
    regular: [],
    seafood_shellfish_nut: [],
    seafood_shellfish: [],
    seafood_nut: [],
    seafood: [],
    shellfish_nut: [],
    shellfish: [],
    nut: [],
  };

  item.orders.forEach((order) => {
    const data = {
      order_id: order.order_id,
      service_delivery_date: order.service_delivery_date,
    };

    if (order.allergy_tags) {
      const seafood_allergy = order.allergy_tags.seafood;
      const shellfish_allergy = order.allergy_tags.shellfish;
      const nut_allergy = order.allergy_tags.nut;

      if (seafood_allergy && shellfish_allergy && nut_allergy) {
        allergies.seafood_shellfish_nut.push(data);
      } else if (seafood_allergy && shellfish_allergy) {
        allergies.seafood_shellfish.push(data);
      } else if (seafood_allergy && nut_allergy) {
        allergies.seafood_nut.push(data);
      } else if (seafood_allergy) {
        allergies.seafood.push(data);
      } else if (shellfish_allergy && nut_allergy) {
        allergies.shellfish_nut.push(data);
      } else if (shellfish_allergy) {
        allergies.shellfish.push(data);
      } else if (nut_allergy) {
        allergies.nut.push(data);
      } else {
        allergies.regular.push(data);
      }
    } else {
      allergies.regular.push(data);
    }
  });

  Object.keys(allergies).forEach((allergy) => {
    if (allergies[allergy].length < 1) {
      delete allergies[allergy];
    }
  });

  return allergies;
};
