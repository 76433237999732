<template>
  <div>
    <template v-if="allowStatusShow">
      <template v-if="functionStatus">
        <span class="font-sm">
          <el-tag type="success" class="text-normalize">
            {{ functionLabel }}
          </el-tag>
        </span>
        <span class="font-xsm text-grey-tertiary marker">
          <span v-if="functionValue" class="sp">{{ functionValue }}</span> by
          {{ formatName(functionActor) }}
        </span>
      </template>
      <template v-else>
        <el-tag
          type="warning"
          :class="{ 'text-cursor': allowMarking }"
          @dblclick.native="allowMarking ? $emit('action') : false"
        >
          {{
            period === "today" ? "Pending" : `Not ${functions[markFunction]}`
          }}
        </el-tag>
      </template>
    </template>
    <span v-else class="font-sm">-</span>
  </div>
</template>

<script>
export default {
  name: "OrderFunctionStatus",
  props: {
    period: {
      type: String,
      default: "",
    },
    allowMarking: {
      type: Boolean,
      default: false,
    },
    allowStatusShow: {
      type: Boolean,
      default: true,
    },
    markFunction: {
      type: String,
      default: "",
    },
    functionStatus: {
      type: Boolean,
      default: false,
    },
    functionTime: {
      type: [String, Date],
      default: "",
    },
    functionValue: {
      type: String,
      default: "",
    },
    functionActor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      functions: {
        plating: "Plated",
        sealing: "Sealed",
        sleeving: "Sleeved",
        packing: "Packed",
        dispatching: "Dispatched",
        sp_assigned: "Assigned",
        rider_enroute: "En Route",
        rider_arrived: "Arrived",
        pickup_rider_arrived: "Arrived",
        rider_pickup: "Pickup",
        rider_delivered: "Delivered",
        pickup_rider_delivered: "Delivered",
        pickup_qc: "Checked",
        sent_to_sp: "Sent",
        received_from_sp: "Received",
        delivery_qc: "Checked",
        dispatched: "Dispatched",
        delivery_rider_arrived: "Arrived",
        delivery_rider_delivered: "Delivered",
        cleaner_assigned: "Assigned",
        cleaner_enroute: "En Route",
        cleaner_arrived: "Arrived",
        cleaning_started: "Started",
        cleaning_done: "Completed",
        sp_enroute: "En Route",
        sp_arrived: "Arrived",
        completed: "Completed",
      },
    };
  },
  computed: {
    functionLabel() {
      console.log(this.functionActor, this.functionValue)
      return `${this.functions[this.markFunction]} at ${this.formatTime(
        this.functionTime,
        "12h"
      )}`;
    },
  },
  method: {
    action() {
      this.allowMarking ? this.$emit("action") : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  .marker {
    margin: 0 7px;
    font-weight: 400;

    .sp {
      font-weight: 600;
    }
  }
}
</style>
