<template>
  <div>
    <eden-page-header
      :title="'Combo Check'"
      :subtitle="'History'"
      :section="title"
    />
    <eden-table-actions :title="'Combo Check History'" @search="setQuery">
      <template slot="actions">
        <el-button
          type="plain"
          icon="eden-icon-upload"
          class="ml-10"
          :disabled="loading || !combos.length"
          @click="exporting = true"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <p v-if="loading">Loading</p>
    <template v-else>
      <combo-check-items-table
        :combos="combos"
        :allow-marking="false"
        :allow-status-show="true"
      />
    </template>
    <combo-check-export :show.sync="exporting" :data="pageData" />
  </div>
</template>
<script>
import combocheck from "@/requests/stewards/combocheck";
import { formatItems } from "@/components/Stewards/format-items";
import ComboCheckExport from "@/components/Stewards/ComboCheck/Export/ComboCheckExport";
import ComboCheckItemsTable from "@/components/Stewards/ComboCheck/ComboCheckItemsTable";

export default {
  name: "ComboSingleHistory",
  components: { ComboCheckItemsTable, ComboCheckExport },
  data() {
    return {
      searchQuery: "",
      pageData: [],
      loading: false,
      exporting: false,
    };
  },
  computed: {
    date() {
      return this.$route.params.date;
    },
    servicePartnerId() {
      return this.getDefaultSp("meal");
    },
    title() {
      return this.formatDate(this.date);
    },
    combos() {
      const query = this.formatQueryText(this.searchQuery);

      if (query !== "") {
        return this.pageData.filter((combo) => {
          return combo.name.toLowerCase().includes(query);
        });
      } else {
        return this.pageData;
      }
    },
  },
  created() {
    this.getComboItemsHistory();
  },
  methods: {
    getComboItemsHistory() {
      this.loading = true;
      combocheck
        .combo(this.servicePartnerId, this.date)
        .then((response) => {
          if (response.data.status) {
            this.pageData = this.sortList(
              formatItems(response.data.data, "combo"),
              "name"
            );
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setQuery(query) {
      this.searchQuery = query;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  .marker {
    margin: 0 13px;
    font-weight: 300;
  }
}
</style>
